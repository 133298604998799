import React from "react";
import ThumbnailComponent from "../ThumbnailComponent/ThumbnailComponent.js";
import { Skeleton } from "@mui/material";
import PlaylistThumbnail from "../PlaylistThumbnail/PlaylistThumbnail.js";
import ChannelName from "../ChannelName/ChannelName.js";
import {
  formatTimeAgo,
  parseViews,
  formatSimpleDate,
} from "../../utils/video.js";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { showSignup } from "../../actions/global.action.js";
import { videoService } from "../../services/video.service.ts";
import "./styles.scss";

const ItemCard = ({
  object,
  handleClick,
  handleClose = () => {},
  type = "video",
  level = "medium",
  is_horizontal = false,
  include_desc = true,
  is_banner = false,
  is_highlighted = false,
  isSkeleton = false,
  redirectToChannelPage = true,
}) => {
  /*
      Card that displays one video/playlist with relevant info.
      - object: video/playlist to display in a card
      - handleClick: optional function for special click handling
      - handleClose: optional function for closing (used for SearchModal)
      - type: 'video', 'playlist', or 'dashboard-video' to render different sub-elements
      - is_horizontal: boolean for whether card is horizontal
      - include_desc: boolean to display description
      - is_banner: boolean special for banner videos (Landing Page)
      - is_highlighted: boolean special for current video on Up Next (Playlist Page)
      - isSkeleton: boolean for skeleton display (Landing page)
    */

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClickVideo = async () => {
    if (isSkeleton) {
      return;
    } else if (handleClick) {
      handleClick();
    } else {
      if (object.card_type) {
        navigate(`/card/${object.id}`);
        return;
      }
      if (is_horizontal) {
        await videoService.canWatchMoreVideos().then((response) => {
          if (response.can_watch) {
            navigate(`/video/${object.id}`);
          } else {
            dispatch(showSignup());
          }
        });
      } else {
        navigate(`/video/${object.id}`);
      }
    }
    handleClose();
  };
  const handleClickPlaylist = async () => {
    if (isSkeleton) {
      return;
    } else if (is_horizontal) {
      await videoService.canWatchMoreVideos().then((response) => {
        if (response.can_watch) {
          if (object.current_video) {
            navigate(`/playlist/${object.id}?current=${object.current_video}`);
          } else {
            navigate(`/playlist/${object.id}`);
          }
        } else {
          dispatch(showSignup());
        }
      });
    } else {
      navigate(`/playlist/${object.object_id}`);
    }
    handleClose();
  };

  const containerClasses = () => {
    if (is_horizontal) {
      let classes = "container-horizontal ";
      if (is_banner) {
        if (object.video_type === "portrait") {
          classes += "banner-portrait";
        } else {
          classes += "banner-landscape";
        }
      }
      return classes;
    } else if (type === "dashboard-video") {
      return "container-dashboard";
    } else if (is_highlighted) {
      return "container-highlighted";
    }
  };

  const titleClasses = () => {
    let classes = "";
    if (is_horizontal) {
      classes += " title-horizontal";
    } else {
      classes += " title-vertical";
    }
    if (type !== "dashboard-video" && !is_horizontal) {
      classes += " title-wrap";
    } else {
      classes += " title-two-lines";
    }
    return classes;
  };

  return (
    <div className="shell-comp">
      <div className={`basic-info  ${containerClasses()}`}>
        {type === "playlist" ? (
          <div
            onClick={() => handleClickPlaylist()}
            className={`playlist-thumbnail thumbnail-${level}`}
          >
            {!isSkeleton ? (
              <PlaylistThumbnail
                playlist={object}
                noPhoto={false}
                clickHandled={true}
                isSkeleton={isSkeleton}
              />
            ) : (
              <Skeleton
                variant="rectangular"
                width="100%"
                height="100%"
                style={{ borderRadius: "12px", cursor: "default" }}
              />
            )}
          </div>
        ) : (
          <div
            className={`video-thumbnail thumbnail-${level} ${is_banner ? (object.video_type === "portrait" ? "banner-vertical" : "banner-horizontal") : ""}`}
            onClick={() => handleClickVideo()}
          >
            {!isSkeleton ? (
              <ThumbnailComponent video={object} />
            ) : (
              <Skeleton
                variant="rectangular"
                width="100%"
                height="100%"
                style={{ borderRadius: "12px", cursor: "default" }}
              />
            )}
          </div>
        )}
        {include_desc && (
          <div
            className={
              "video-description " +
              (is_horizontal ? "descripton-horizontal" : "description-vertical")
            }
            style={{
              gap: level === "small" ? "4px" : "12px",
            }}
          >
            <div className={`video-title  ${titleClasses()}`}>
              {!isSkeleton ? (
                object.title
              ) : (
                <Skeleton variant="text" width="100%" />
              )}
            </div>
            <div className="channel-name ">
              <ChannelName
                author={type === "playlist" ? object.user : object.author}
                isSkeleton={isSkeleton}
                redirectToChannelPage={redirectToChannelPage}
              />
            </div>
            {!isSkeleton ? (
              <div
                className={
                  "video-details " +
                  (is_horizontal ? "details-horizontal" : "details-vertical")
                }
              >
                {type === "video" || type === "playlist" ? (
                  <>
                    <p className="video-views">
                      {parseViews(object.views)} view
                      {object.views !== 1 ? "s" : ""}
                    </p>
                    <p>
                      {formatTimeAgo(
                        type === "video" ? object.date : object.created_at,
                      )}
                    </p>
                  </>
                ) : (
                  object.date && (
                    <div className="upload-date">
                      Upload Date:{" "}
                      {formatSimpleDate(object.date, {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric",
                      })}
                    </div>
                  )
                )}
              </div>
            ) : (
              <div
                className={
                  "video-details " +
                  (is_horizontal ? "details-horizontal" : "details-vertical")
                }
              >
                <Skeleton variant="text" width="60%" className="video-views" />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ItemCard;
