import React, { useEffect, useState } from "react";
import ButtonComponent from "../ButtonComponent/ButtonComponent";
import MyCard from "./MyCard";
import Spinner from "../Spinner/Spinner";
import { cardService } from "../../services/card.service.ts";
import UploadAssCard from "../../modals/UploadAssCard/UploadAssCard";
import "./styles.scss";

const MyCards = () => {
  /*
    Subpage in Dashboard > Cards tab, where a user can see a list of card they created.
  */

  const [loading, setLoading] = useState(true);
  const [showUpload, setShowUpload] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchCards();
  }, []);

  const fetchCards = async () => {
    try {
      const response = await cardService.getUserCards();
      setData(response);
    } catch (error) {
      console.error("Error fetching videos:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = () => {
    fetchCards();
  };

  const cardItems = data
    .sort((a, b) => new Date(b.date) - new Date(a.date))
    .map((card) => (
      <MyCard key={card.id} card={card} handleChange={handleChange} />
    ));

  return (
    data && (
      <div className="my-content">
        <div className="initial-heading">
          <div>Cards</div>
          <div className="initial-heading-button">
            <ButtonComponent
              text="Add Cards"
              icon={true}
              uploadIcon={true}
              handleClick={() => {
                setShowUpload(true);
              }}
            />
          </div>
          {showUpload && (
            <UploadAssCard handleClose={() => setShowUpload(false)} />
          )}
        </div>
        <div className="table-component">
          <div className="table-headings-component">
            <div className="first-child card-styling">Card Information</div>
            {/* <div className="date-created">Date Created</div> */}
            <div className="second-child">Stats</div>
            <div className="third-child">Visibility Status</div>
            <div className="fourth-child">Controls</div>
          </div>
          <div className="video-items">
            {loading ? (
              <div className="loading-container-styling">
                <Spinner />
              </div>
            ) : (
              <>{cardItems}</>
            )}
          </div>
        </div>
      </div>
    )
  );
};

export default MyCards;
