import React, { useRef, useEffect } from "react";
import VideoJS from "./VideoJS";
import { useObserver } from "../../hooks/useObserver";
import { videoService } from "../../services/video.service.ts";
import { VIEW_VIDEO_PERCENTAGE } from "../../utils/constants";
import videojs from "video.js";
import CustomTimeDisplay from "./CustomTimeDisplay";
// import PlaybackSpeedButton from "./PlaybackSpeedButton";
import { useSelector, useDispatch } from "react-redux";
import { useInterval } from "../../hooks/useInterval.js";
import AutoplayButton from "./AutoplayButton";
import {
  setVideoSpeed,
  setAudioLevel,
  setMute,
  setAutoplay,
} from "../../actions/player.js";
import "./styles.scss";
import { useLocation } from "react-router-dom";

function Video({
  id,
  video_type,
  video_url,
  play,
  isEmbedded = false,
  setSelectedOption = { setSelectedOption },
  setIsVisible,
  isDetailMode,
  setIsDetailMode,
  poster,
}) {
  /*
  A component used for showing a video to the user.

  todo: replace icons with the right ones
  todo: update the styling of the navigation icons, once their final design is ready
   */
  const currentPlaylist = useSelector(
    (state) => state.playlist.currentPlaylist,
  );
  const showPlaylistWatch = useSelector(
    (state) => state.playlist.watchingPlaylist,
  );
  const currentRefs = useSelector((state) => state.video.playerRefs);
  const videoSpeed = useSelector((state) => state.player.videoSpeed);
  const audioLevel = useSelector((state) => state.player.audioLevel);
  const isMuted = useSelector((state) => state.player.isMuted);
  const isAutoplay = useSelector((state) => state.player.isAutoplay);
  const playerRef = useRef(null);
  const vidContainerRef = useRef();
  const isVisible = useObserver(vidContainerRef);
  const dispatch = useDispatch();
  const location = useLocation();
  const showModal = useSelector((state) => state.global.showModal);

  let aspectRatio = video_type === "landscape" ? "16:9" : "9:16";
  let viewed = false;
  let watched = false;
  let watchProgressId = null;

  const getPlaylistId = () => {
    let playlistToFetch = null;
    if (location.pathname.includes("/playlist/")) {
      const pathPart = location.pathname.split("/playlist/")[1];
      playlistToFetch = pathPart.endsWith("/")
        ? pathPart.slice(0, -1)
        : pathPart;
    }
    return playlistToFetch;
  };

  useEffect(() => {
    setIsVisible(isVisible);
  }, [isVisible]);

  const videoJsOptions = {
    autoplay: isAutoplay,
    controls: true,
    responsive: true,
    preload: "none",
    fluid: true,
    fill: true,
    aspectRatio: aspectRatio,
    loop: true,
    poster: poster,
    enableSmoothSeeking: true,
    playbackRates: [0.75, 1, 1.25, 1.5, 1.75, 2],
    controlBar: {
      remainingTimeDisplay: {
        displayNegative: false,
      },
      pictureInPictureToggle: true, // note that styling should be added for the PiP component
      children: [
        "playToggle",
        "volumePanel",
        "customControlSpacer",
        "customTimeDisplay",
        "progressControl",
        "liveDisplay",
        "remainingTimeDisplay",
        "customControlSpacer",
        "playbackRateMenuButton",
        "chaptersButton",
        "subtitlesButton",
        "captionsButton",
        "audioTrackButton",
        // "PlaybackSpeedButton",
        "fullscreenToggle",
        "AutoplayButton",
      ],
    },
    sources: [
      {
        src: video_url,
        type: "video/mp4",
      },
    ],
    // uncomment the following code to enable subtitles
    // tracks: [
    //   {
    //     kind: 'subtitles',
    //     src: '/subtitles/english_subtitles.vtt', // Path to the generated VTT file
    //     srclang: 'en',
    //     label: 'English',
    //     default: true,
    //   },
    // ],
    errorDisplay: false,
  };

  const handlePlayerReady = (player) => {
    playerRef.current = player;

    player.reloadSourceOnError({
      getSource: function (reload) {
        reload({
          src: video_url,
          type: "video/mp4",
        });
      },
      errorInterval: 5,
    });

    player.on("touchstart", function () {
      if (player.paused()) {
        player.play();
      } else {
        player.pause();
      }
    });

    // const handleSpeedChange = (newSpeed) => {
    //   dispatch(setVideoSpeed(newSpeed));
    // };

    const handleAutoplayChange = (status) => {
      dispatch(setAutoplay(status));
    };

    player.on("timeupdate", async () => {
      const timeWatched = getPlayedTime(player);
      if (timeWatched.total > 0 && !viewed) {
        viewed = true;
        try {
          let playlistId = null;
          if (showPlaylistWatch === "watch") {
            playlistId = currentPlaylist.object_id;
          } else {
            playlistId = getPlaylistId();
          }
          const response = await videoService.addToWatchHistory(id, playlistId);
          watchProgressId = response.watch_progress_id;
        } catch (error) {
          console.error("Error:", error);
        }
      }
      if (timeWatched.percent >= VIEW_VIDEO_PERCENTAGE) {
        handleWatch(timeWatched.percent);
      }
      watchProgressId && updateWatchProgress(timeWatched.percent);
    });

    player.playbackRate(videoSpeed);
    player.volume(audioLevel);
    player.muted(isMuted);

    player.on("ratechange", () => {
      dispatch(setVideoSpeed(player.playbackRate()));
    });

    player.on("volumechange", () => {
      dispatch(setAudioLevel(player.volume()));
      dispatch(setMute(player.muted()));
    });

    //TBD: Add an alternative context menu
    player.on("contextmenu", (event) => {
      event.preventDefault();
    });

    //NOTE: The following code is commented out because it's not needed for now
    // const PlaylistButton = videojs.getComponent('Button');
    // const playlistButton = new PlaylistButton(player, {
    //   text: 'Playlist',
    // });
    // playlistButton.addClass('vjs-icon-playlist');
    // playlistButton.controlText('Playlist');
    // playlistButton.el().innerHTML = `<img src=${playlistIcon} alt="Playlist" style="width: 24px; height: 24px;" />`;
    // playlistButton.on('click', () => {
    //   navigate('/home');
    // });
    // player.controlBar.addChild(playlistButton, {}, 15); // Add at the start of the control bar

    if (!player.controlBar.getChild("CustomTimeDisplay")) {
      videojs.registerComponent("CustomTimeDisplay", CustomTimeDisplay);
      player.controlBar.addChild("CustomTimeDisplay", {}, 4);
    }

    // player.controlBar.removeChild("PlaybackSpeedButton");
    // videojs.registerComponent("PlaybackSpeedButton", PlaybackSpeedButton);
    // player.controlBar.addChild(
    //   "PlaybackSpeedButton",
    //   { videoSpeed, onSpeedChange: handleSpeedChange },
    //   11,
    // );

    player.controlBar.removeChild("AutoplayButton");
    videojs.registerComponent("AutoplayButton", AutoplayButton);
    player.controlBar.addChild(
      "AutoplayButton",
      { isAutoplay, onAutoplayChange: handleAutoplayChange },

      11,
    );
  };

  const getPlayedTime = (player) => {
    let totalPlayed = 0;
    let played = player.played();

    for (let i = 0; i < played.length; i++) {
      totalPlayed += played.end(i) - played.start(i);
    }

    return {
      total: totalPlayed,
      percent: totalPlayed / player.duration(),
    };
  };

  const handleWatch = async (progress) => {
    if (!watched) {
      watched = true;
      try {
        await videoService.updateViews(id, progress, watchProgressId);
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  const updateWatchProgress = async (progress) => {
    try {
      await videoService.updateWatchProgress(id, progress, watchProgressId);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    if (play && playerRef.current) {
      try {
        playerRef.current?.currentTime(currentRefs[id]?.currentTime());
      } catch (error) {
        console.error(error);
      }
      var playPromise = playerRef.current?.play();
      playPromise?.catch((error) => {
        console.error(error);
      });
    } else {
      try {
        currentRefs[id]?.currentTime(playerRef.current?.currentTime());
      } catch (error) {
        console.error(error);
      }
      playerRef.current?.pause();
    }
  }, [play]);

  useEffect(() => {
    if (playerRef.current) {
      const currentSpeed = playerRef.current.playbackRate();
      if (currentSpeed !== videoSpeed) {
        playerRef.current.playbackRate(videoSpeed);
      }
    }
  }, [videoSpeed]);

  useEffect(() => {
    if (playerRef.current) {
      const currentIsAutoplay = playerRef.current.autoplay();
      if (currentIsAutoplay !== isAutoplay) {
        playerRef.current.autoplay(isAutoplay);
      }
    }
  }, [isAutoplay]);

  useEffect(() => {
    if (playerRef.current) {
      playerRef.current.volume(audioLevel);
      playerRef.current.muted(isMuted);
    }
  }, [audioLevel, isMuted]);

  useInterval(() => {
    if (!isVisible) {
      playerRef.current?.pause();
    }
  }, 20);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (
        (event.target.classList.contains("tiptap") &&
          event.target.classList.contains("ProseMirror")) ||
        !isVisible ||
        event.target.classList.contains("text-input-component-input")
      ) {
        return;
      }

      if (showModal && event.key === " ") {
        event.preventDefault();
        return;
      }

      if (
        !playerRef.current ||
        playerRef.current?.paused === undefined ||
        showModal
      ) {
        return;
      }

      const currentTime = playerRef.current.currentTime();

      if (event.ctrlKey || event.altKey || event.metaKey || event.shiftKey) {
        return;
      }

      switch (event.key.toLowerCase()) {
        case "f":
          if (document.fullscreenElement) {
            document.exitFullscreen();
          } else if (playerRef.current.requestFullscreen) {
            playerRef.current.requestFullscreen().catch((err) => {
              console.error(
                `Failed to enter fullscreen mode: ${err.message} (${err.name})`,
              );
            });
          } else if (playerRef.current.webkitRequestFullscreen) {
            playerRef.current.webkitRequestFullscreen();
          } else if (playerRef.current.mozRequestFullScreen) {
            playerRef.current.mozRequestFullScreen();
          } else if (playerRef.current.msRequestFullscreen) {
            playerRef.current.msRequestFullscreen();
          }
          event.preventDefault();
          break;
        case "m":
          dispatch(setMute(!playerRef.current.muted()));
          event.preventDefault();
          break;
        case " ":
        case "k":
          if (!playerRef.current?.paused()) {
            playerRef.current?.pause();
          } else if (isVisible) {
            playerRef.current?.play();
          }
          event.preventDefault();
          break;
        case "arrowright":
        case "l":
          playerRef.current.currentTime(currentTime + 5);
          event.preventDefault();
          break;
        case "arrowleft":
        case "j":
          playerRef.current.currentTime(currentTime - 5);
          event.preventDefault();
          break;
        default:
          break;
      }
    };

    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [isVisible, isDetailMode, showModal]);

  let videoOrientationClassName =
    video_type === "portrait" ? "video-portrait" : "video-landscape";
  if (isEmbedded) {
    videoOrientationClassName += "-embedded";
  }
  const videoClassName = `${videoOrientationClassName} video video-container`;

  return (
    <div ref={vidContainerRef} className={videoClassName}>
      <VideoJS
        key={video_url}
        id={id}
        options={videoJsOptions}
        onReady={handlePlayerReady}
        isVisible={isVisible}
      />
    </div>
  );
}

export default Video;
